import React, { useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

function LayerRealationships(props) {
    const { listMapInfomations, listChildInfomations } = props;
    const [infoContentChange, setInfoContentChange] = useState();

    useEffect(() => {
        if (listChildInfomations && listChildInfomations.length > 0) {
            setInfoContentChange(listChildInfomations)
            console.log('kfjdsakfjncsdf', listChildInfomations)
        }
    }, [listMapInfomations, listChildInfomations])

    const getDataRow = (index, label, rowValue) => {
        return (
            <tr key={index}>
                <td className="row-label" width="50%">{label}</td>
                <td width="50%">{rowValue}</td>
            </tr>
        )
    }
    const getRowData = (data, index) => {
        if (data && data.label && data.value) {
            const rowLabel = data.label;
            const rowValue = data.value;
            const arrRow = rowLabel.split('_');
            let label = data.label;
            let labelPrefix = '';
            if (Array.isArray(arrRow) && arrRow.length == 2) {
                labelPrefix = arrRow[0];
                label = arrRow[1];
            }
            return getDataRow(index, label, rowValue);
        } else return;
    }

    return (
        <>
            {listChildInfomations && listChildInfomations.length > 0 ? (
                <details open>
                    <summary>
                        Thông tin điều chỉnh
                        <img
                            className="position-absolute icon-dropdow right-position"
                            src={require("../../../../assets/icon/arrow-down.png")}
                            alt="arrow-down"
                        />
                        <img
                            className="position-absolute icon-dropright right-position"
                            src={require("../../../../assets/icon/arrow-right.png")}
                            alt="arrow-right"
                        />
                    </summary>

                    <div className="info-table">
                        {infoContentChange?.length > 0 && infoContentChange.map((item, ind) => (
                            <div key={ind}>
                                {item?.map((data) => {
                                    if (data.value && data.label == 'qddc') {
                                        return (
                                            <div className="info_table">
                                                <p>{data.value}</p>
                                            </div>
                                        )
                                    }
                                })}
                                <table >
                                    <thead>
                                        <th className="row-label" width="50%">Tên trường</th>
                                        <th width="50%">Giá trị</th>
                                    </thead>
                                    <tbody>
                                        {item?.map((data, index) => {
                                            if (data.value && data.label != 'qddc') {
                                                return getRowData(data, index);
                                            }
                                        }
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </details>
            ) : (<></>)}
        </>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        }, dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LayerRealationships);