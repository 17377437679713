import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as homePageAction from "../../../redux/store/home-page/home-page.store";
import './lookupPlanning.scss'
import { APIUrlDefault } from "../../../utils/configuration";
import dateformat from "dateformat";
import { LazyLoadImage } from 'react-lazy-load-image-component';
export const LookupPlanningType = (props) => {
    const {
        showLoading,
        planningTypeId,
        planningName,
    } = props;

    const [listResult, setListResult] = useState([]);
    const [paging, setPaging] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [firstPlanning, setFirstPlanning] = useState({});
    const pageSize = 4;
    useEffect(() => {
        onGetData();
    }, []);

    const onGetData = () => {
        showLoading(true);
        Promise.all([
            onGetPlanningSearchByIdName({
                planningTypeId,
                pageIndex: 1,
                pageSize: pageSize,
            }),
        ])
            .then((res) => {
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };


    const onGetPlanningSearchByIdName = (data) => {
        return new Promise((resolve, reject) => {
            homePageAction.GetPlanningSearchByIdNameHome(data.planningTypeId, data.pageIndex, data.pageSize)
                .then((res) => {
                    setListResult(
                        res &&
                            res.content &&
                            res.content.items
                            && res.content.items.length > 0 ? res.content.items : []
                    );
                    setPaging(res.content);
                    setCurrentPage(res.content.pageIndex);
                    setFirstPlanning(res.content.items[0]);

                    resolve(res);
                },
                    (err) => {
                        reject(err);
                    })

        })
    }

    const getNextPage = () => {
        let nextPage = 1;
        if (currentPage > 0) {
            nextPage = currentPage + 1;
        }
        onGetPlanningSearchByIdName({
            pageIndex: nextPage,
            planningTypeId,
            pageSize: pageSize
        })
    }

    const getPreviousPage = () => {
        let prePage = 1;
        if (currentPage > 1) {
            prePage = currentPage - 1;
        }
        onGetPlanningSearchByIdName({
            pageIndex: prePage,
            planningTypeId,
            pageSize: pageSize
        })
    }

    return (
        <div>
            <div className="container">
                <div className="title__planning-type">
                    <div className="row">
                        <div className="col">
                            <Link to={'ban-do-quy-hoach?key=' + planningTypeId}><h3>{planningName}</h3></Link>
                        </div>
                        <div className="col">
                            <div className="pagination-nav">
                                {
                                    paging && paging.hasPreviousPage && (
                                        <a className="nav-action" onClick={getPreviousPage}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0)">
                                                    <path
                                                        d="M2.39225 5.35688L6.8892 9.85234C7.08652 10.0492 7.40622 10.0492 7.60404 9.85234C7.80137 9.65551 7.80137 9.33582 7.60404 9.13899L3.46378 5.00023L7.60354 0.861463C7.80087 0.664638 7.80087 0.344942 7.60354 0.147619C7.40622 -0.0492063 7.08603 -0.0492063 6.8887 0.147619L2.39175 4.64304C2.19746 4.83783 2.19746 5.16255 2.39225 5.35688Z"
                                                        fill="#333333" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect width="10" height="10" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    )
                                }
                                {paging && paging.hasNextPage && (
                                    <a className="nav-action" onClick={getNextPage}>
                                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0)">
                                                <path
                                                    d="M7.60775 4.64312L3.1108 0.147661C2.91348 -0.0491642 2.59378 -0.0491643 2.39596 0.147661C2.19863 0.344486 2.19863 0.664181 2.39596 0.861006L6.53622 4.99977L2.39646 9.13854C2.19913 9.33536 2.19913 9.65506 2.39646 9.85238C2.59378 10.0492 2.91397 10.0492 3.1113 9.85238L7.60825 5.35696C7.80254 5.16217 7.80254 4.83745 7.60775 4.64312Z"
                                                    fill="#333333" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="10" height="10" fill="white" transform="translate(10 10) rotate(-180)" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 mb-4">
                        <Link to={"/ban-do-quy-hoach/" + firstPlanning.id}>
                            <LazyLoadImage
                                className="first-img"
                                src={APIUrlDefault + firstPlanning.photo}
                                onError={(e) =>
                                    (e.target.src = require("../../../assets/image/noImage.png"))
                                }
                                alt={firstPlanning.photo}>
                            </LazyLoadImage>
                        </Link>
                        <div className="planning__date">{dateformat(firstPlanning.publishDate, "dd/mm/yyyy")}</div>
                        <Link to={"/ban-do-quy-hoach/" + firstPlanning.id} className="planning__title">
                            <p>{firstPlanning.name}</p>
                        </Link>
                    </div>
                    <div className="col-lg-6">
                        <div className="">
                            {listResult.slice(1).map((item, index) => (
                                <div key={index} className='row planning__item'>
                                    <div className="col-5">
                                        <Link to={"/ban-do-quy-hoach/" + item.id}>
                                            <LazyLoadImage
                                                className="planning-img"
                                                src={APIUrlDefault + item.photo}
                                                onError={(e) =>
                                                    (e.target.src = require("../../../assets/image/noImage.png"))
                                                }
                                                alt={item.photo}>
                                            </LazyLoadImage>
                                        </Link>
                                    </div>
                                    <div className="col-7">
                                        <Link to={"/ban-do-quy-hoach/" + item.id} className="planning__title">
                                            <p>{item.name}</p>
                                        </Link>
                                        <div className="planning__date">{dateformat(item.publishDate, "dd/mm/yyyy")}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

